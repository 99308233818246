//require('bootstrap/js/src/modal');

$(function() {
    jQuery(window).on("scroll", function (e){
        var scrollTop = jQuery(window).scrollTop(),
            table = jQuery(".table-compare"),
            container = jQuery(".table-compare");

        if (container.is(":visible")){
            if (scrollTop >= (table.offset().top - parseInt(jQuery('header').outerHeight())) && scrollTop < table.height() + table.offset().top){
                jQuery(".fixed-table").show();
            } else {
                jQuery(".fixed-table").show();

            }
        }
    });
    function initShowMoreBlock(){
        jQuery('.show-more-block').each(function(){
            var container = jQuery(this),
                button = jQuery('.show-more-button', this);
            console.log(button);
            button.on('click', clickHandler);

            function clickHandler(e){
                e.preventDefault();

                container.addClass('full-list-is-shown');
            }
        });
    }

    initShowMoreBlock();
    //$('body').scrollspy({ target: '#nav-stacked' })

    $(".home--top").click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    $("#nav-stacked ul li a[href^='#']").on('click', function(e) {

        // prevent default anchor click behavior
        e.preventDefault();

        // store hash
        var hash = this.hash;

        // animate
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 500, function(){

            // when done, add hash to url
            // (default click behaviour)
            window.location.hash = hash;
        });

    });
    $('.nav-stacked').affix({
        offset: {
            top: function () {
                return (this.top = $('header').outerHeight(true)+100)
            },
            bottom: function () {
                return (this.bottom = $('.footer').outerHeight(true))
            }
        }
    })
    $('#company-logos').affix({
        offset: {
            top: function () {
                return (this.top = $('header').outerHeight(true)+100)
            },
            bottom: function () {
                return (this.bottom = $('.footer').outerHeight(true))
            }
        }
    })

    $('.navigation li a').each(function() {
        var pathname = window.location.pathname;
        var thislink = $(this).attr('href');
        if(thislink === pathname){$(this).addClass('active');}
    });
    $("#ideals-from-main").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Main',
                eventLabel: 'iDeals'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#intralinks").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Profile ',
                eventLabel: 'Intralinks'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#merrill").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Profile ',
                eventLabel: 'Merrill Datasite'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#brainloop").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Profile ',
                eventLabel: 'Brainloop'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#watchdox").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Profile ',
                eventLabel: 'Watchdox'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#box").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'External Clicks',
                eventAction: 'Visit Website from Profile ',
                eventLabel: 'BOX VDR'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#ideals-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'iDeals'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#intralinks-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'Intralinks'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#merrill-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'Merrill Datasite'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#brainloop-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'Brainloop'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#watchdox-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'Watchdox'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    $("#box-profile").click(function() {
        try {
            ga('send', {
                hitType: 'event',
                eventCategory: 'Internal Clicks',
                eventAction: 'View Profile from Main',
                eventLabel: 'BOX VDR'
            });
        } catch (e) {
            if (window.console) {
                console.log(e);
            }
        }
    });
    /*$(document).ready(function(){
        $('#contact-form').submit(function(e){
            e.preventDefault();
            var domain = window.location.hostname;
            var form = $(this);
            var form_data = form.serialize();
            $.ajax({
                url: "//"+domain+"/wp-admin/admin-ajax.php",
                type: "POST",
                data: "action=contact_form&"+form_data,
                success: function(data){
                   console.log('sucs');
				   form.find('.form-control').val('');
				   form.find('.form-msg').text('I dati inviati!');
                },
                error: function(error){
                    console.log('Some Error!');
                }
            });

            return false;
        });
    });*/
    $.fn.serializeObject=function(){"use strict";var a={},b=function(b,c){var d=a[c.name];"undefined"!=typeof d&&d!==null?$.isArray(d)?d.push(c.value):a[c.name]=[d,c.value]:a[c.name]=c.value};return $.each(this.serializeArray(),b),a};

    function createGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    $(document).ready(function(){

        $('body').scrollspy({ target: '#toc' })

        $('.home--block-back').each(function (){
            var left = '-' + $(this).offset().left + 'px';
            $(this).css('left', left );
            $(this).css('width', $('body').innerWidth() + 'px');
        });


        $('#contact-form').submit(function(e){
            e.preventDefault();
            var domain = window.location.hostname;
            var form = $(this);
            var form_data = form.serializeObject();
            console.log(getCookie('refferedFrom'));
            console.log(getCookie('landingPage'));
            $.ajax({
                url: "//"+domain+"/wp-admin/admin-ajax.php",
                type: "POST",
                data: {
                    action: 'contact_form',
                    form: form_data,
                    referredFrom : getCookie('refferedFrom'),
                    landingPage : getCookie('landingPage'),
                    submittedAt: window.location.href,
                    guid: createGuid()
                },
                success: function(data){
                    console.log(data);
                    form.find('.form-control').val('');
                    form.find('.form-msg').text('I dati inviati!');
                },
                error: function(error){
                    console.log('Some Error!');
                }
            });

            return false;
        });
    });
    jQuery(document).ready(function($){
        if($('body').hasClass('page-id-527')){
            let link = $('.banner-wrap a');
            let src = link.attr('href');
            src = src.replace('virtual-dataroom.it%26','virtual-dataroom.it.boardroom%26');
            link.attr('href', src);
        }

        $('.burger-menu').click(function(){
            $('.main-nav__list').toggleClass('visible');
            $(this).find('.burger__wrap').toggleClass('active');
        });

        $('.menu-item-has-children').click(function(e){
            if( '#' ===  $(e.target).attr('href') ) {
                e.preventDefault();
                $(this).toggleClass('visible');
            }
        });

    });

    jQuery(document).ready(function($) {

        $('#myCarousel').carousel({
            interval: 5000
        });

        $('#carousel-text').html($('#slide-content-0').html());

        //Handles the carousel thumbnails
        $('[id^=carousel-selector-]').click( function(){
            var id = this.id.substr(this.id.lastIndexOf("-") + 1);
            var id = parseInt(id);
            $('#myCarousel').carousel(id);
        });


        // When the carousel slides, auto update the text
        $('#myCarousel').on('slid.bs.carousel', function (e) {
            var id = $('.item.active').data('slide-number');
            $('#carousel-text').html($('#slide-content-'+id).html());
        });
    });


    function popupUse(){
        const slides = document.querySelectorAll('.popup--slide');
        let list = [];
        let num_active = 0;
        const length_slides = parseInt(document.querySelector('.popup--slides').dataset.slides);
        const progress_elements = document.querySelectorAll('.popup--progress-item');

        function load_start(){
            let duration = 1000; // it should finish in 5 seconds !
            let max = 100;
            let i = 0 ;
            let interval = setInterval(function(){
                i++;
                offset  = (max*i)/duration;
                $(".popup--load-orange").css("width", offset + "%");
                $(".popup--line-percent").text(parseInt(offset));
                if(i>=duration){
                    clearInterval(interval);
                }
            }, 1);
        }

        function changeSlide(){
            const slide_active = slides[num_active];
            slide_active.classList.remove('active');

            setTimeout(() => {
                slide_active.nextElementSibling.classList.add('active');
            }, 160);
            progress_elements[num_active].classList.add('finish');
            progress_elements[num_active + 1].classList.add('active');
            num_active++;
        }

        function lastSlides(){
            let active = slides[num_active];
            active.classList.remove('active');
            progress_elements[num_active].classList.add('finish');
            progress_elements[num_active + 1].classList.add('active');
            let p = new Promise(function (resolve, reject){
                setTimeout(() => {
                    active.nextElementSibling.classList.add('active');
                    resolve();
                }, 160);
            });
            p.then(() => {
                return new Promise((resolve, reject) => {
                    num_active++;
                    load_start()
                    setTimeout(() => {
                        active = slides[num_active];
                        progress_elements[num_active].classList.add('finish');
                        progress_elements[num_active + 1].classList.add('active');
                        active.classList.remove('active');
                        resolve();
                    }, 4500);
                })
            })
                .then(() => {
                const last_num = parseInt(document.querySelector('.popup--slides').dataset.slides) + 2;
                list.forEach((item) => {
                    const elem = document.createElement('li');
                    elem.innerText = item;
                    document.querySelector('.popup--last-list').appendChild(elem);
                });
                localStorage.setItem('popupUse', 'close');
                slides[last_num].classList.add('active');
                progress_elements[last_num].classList.add('finish');
            });
        }

        document.querySelectorAll('.popup--slide-button').forEach((item) => {
            item.addEventListener('click', (item) => {
                list.push(item.target.innerText);
                if(num_active < length_slides){
                    changeSlide();
                } else {
                    lastSlides();
                }
            })
        })
    }

    if(document.getElementById('popupUse') && localStorage.getItem('popupUse') != 'close'){
        setTimeout(() => {
            jQuery('#popupUse').modal('toggle');
            popupUse();
        }, 2000)
    }
});
